<template>
  <div class="vps-detail-view">
    <transition name="bubble">
      <!--      <div v-if="loading">-->
      <!--        <base-loader class="stack-detail-view__loader-item" />-->
      <!--      </div>-->
      <!--      <page-block v-else class="vps-detail-view__block">-->
      <page-block class="vps-detail-view__block">
        <main-card>
          <div class="addons">
            <div v-for="(item, i) in addons" :key="i" class="addons__item">
              <div class="addons__label standart-title">
                {{ $t(`${item.name}`) }}
              </div>
              <div class="addons__value standart-text">
                {{ item.value }}
              </div>
            </div>
          </div>
        </main-card>
      </page-block>
    </transition>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
import { OPTariff } from '@/models/OP/OPTariff';
import providerChange from '@/mixins/providerChange';
export default {
  name: 'ServerView',
  components: {
    MainCard,
  },
  mixins: [providerChange],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localDisk: null,
      // isLoading: true,
    };
  },
  computed: {
    volumeId() {
      return this.tariff['os-extended-volumes:volumes_attached'].length > 0
        ? // ? this.tariff['os-extended-volumes:volumes_attached'][0].id
          this.tariff['os-extended-volumes:volumes_attached'].map(x => x.id)
        : null;
    },
    networkDisk() {
      const list = [];
      this.volumeId && this.$store.state.moduleStack.volumes.find(x => x.id === this.volumeId[0])
        ? this.volumeId.forEach(volumeId => {
            list.push(this.$store.state.moduleStack.volumes.find(x => x.id === volumeId).size);
          })
        : null;
      return list;
    },
    getVolume() {
      return this.$store.state.moduleStack.volumes.find(x => x.id === this.volumeId[0]) || '';
    },
    metadata() {
      return this.volumeId.length &&
        this.volumeId[0] &&
        this.getVolume.metadata &&
        Object.entries(this.getVolume.metadata).length
        ? this.getVolume.metadata
        : this.getVolume.volume_image_metadata;
    },
    osName() {
      return this.volumeId[0] && this.metadata //Object.entries(this.metadata).length &&
        ? this.metadata.image_name
        : null;
    },
    addons() {
      const specs = this.tariff.flavor;
      const os =
        this.tariff.image && this.$store.state.moduleStack.images
          ? this.$store.state.moduleStack.images.find(x => x.id === this.tariff.image.id)
            ? this.$store.state.moduleStack.images.find(x => x.id === this.tariff.image.id).name
            : '---'
          : this.volumeId
          ? this.osName
          : '-----';
      let list = [];
      if (os) list.push({ name: 'os', value: os });
      if (specs.name) list.push({ name: 'name', value: specs.name });
      if (specs.vcpus) list.push({ name: 'cpu', value: specs.vcpus.toString() });
      if (specs.ram) list.push({ name: 'ram', value: specs.ram.toString() });
      if (this.localDisk) list.push({ name: 'disk', value: specs.disk });
      if (this.networkDisk)
        this.networkDisk.forEach(x => {
          list.push({ name: 'networkDisk', value: x });
        });
      return list;
    },
  },
  mounted() {
    if (this.localDisk === null) this.getLocalDisk();
  },
  methods: {
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
    getLocalDisk() {
      this.$store
        .dispatch('moduleStack/isLocalDisk', { id: this.$attrs.id, uuid: this.tariff.id })
        .then(async data => {
          if (data && data.result === 'ok') {
            this.localDisk = data.existslocaldisk;
          }
        })
        .catch(e => console.log(e));
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "os": "Источник",
      "name": "Тип конфигурации",
      "cpu": "vCPU",
      "ram": "Память",
      "disk": "Локальный диск",
      "networkDisk": "Сетевой диск"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 160px;
      margin: 0.5em 0.75rem;
    }
  }
}

.addons {
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        border-top: 1px solid var(--border);
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
</style>
